exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-components-about-about-js": () => import("./../../../src/pages/Components/About/about.js" /* webpackChunkName: "component---src-pages-components-about-about-js" */),
  "component---src-pages-components-circular-progress-js": () => import("./../../../src/pages/Components/circularProgress.js" /* webpackChunkName: "component---src-pages-components-circular-progress-js" */),
  "component---src-pages-components-footer-footer-js": () => import("./../../../src/pages/Components/Footer/footer.js" /* webpackChunkName: "component---src-pages-components-footer-footer-js" */),
  "component---src-pages-components-header-header-js": () => import("./../../../src/pages/Components/Header/header.js" /* webpackChunkName: "component---src-pages-components-header-header-js" */),
  "component---src-pages-components-how-it-works-how-it-works-js": () => import("./../../../src/pages/Components/HowItWorks/howItWorks.js" /* webpackChunkName: "component---src-pages-components-how-it-works-how-it-works-js" */),
  "component---src-pages-components-offer-card-offer-card-js": () => import("./../../../src/pages/Components/OfferCard/offerCard.js" /* webpackChunkName: "component---src-pages-components-offer-card-offer-card-js" */),
  "component---src-pages-components-offer-card-offers-js": () => import("./../../../src/pages/Components/OfferCard/offers.js" /* webpackChunkName: "component---src-pages-components-offer-card-offers-js" */),
  "component---src-pages-components-offer-landing-page-js": () => import("./../../../src/pages/Components/offerLandingPage.js" /* webpackChunkName: "component---src-pages-components-offer-landing-page-js" */),
  "component---src-pages-components-offer-listing-page-group-offer-list-js": () => import("./../../../src/pages/Components/OfferListingPage/groupOfferList.js" /* webpackChunkName: "component---src-pages-components-offer-listing-page-group-offer-list-js" */),
  "component---src-pages-components-offer-listing-page-index-js": () => import("./../../../src/pages/Components/OfferListingPage/index.js" /* webpackChunkName: "component---src-pages-components-offer-listing-page-index-js" */),
  "component---src-pages-components-offer-listing-page-timer-js": () => import("./../../../src/pages/Components/OfferListingPage/timer.js" /* webpackChunkName: "component---src-pages-components-offer-listing-page-timer-js" */),
  "component---src-pages-components-svg-js": () => import("./../../../src/pages/Components/svg.js" /* webpackChunkName: "component---src-pages-components-svg-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

